import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin, robotLinkCommon } from 'public/src/pages/common/utils/index.js'
import apiReport from 'public/src/pages/common/apiReport'
import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

function setBusinessConfig() {
  if (typeof window === 'undefined') {
    return
  }
  const { IS_RW, PUBLIC_CDN, lang, langPath, host, LAZY_IMG, SHOP_TRANSIT, COUNTRY_SPECIAL_POSTCODE, SiteUID, currency, appLanguage, NODE_SERVER_ENV, SERVER_TYPE } = gbCommonInfo
  window.GB_BUSINESS_CONFIGS = {
    client: 'm',
    project: 'M',
    langPath,
    isPay: false,
    isRw: IS_RW,
    PUBLIC_CDN,
    lang,
    host,
    LAZY_IMG,
    SHOP_TRANSIT,
    COUNTRY_SPECIAL_POSTCODE,
    SiteUID,
    currency,
    appLanguage,
    NODE_SERVER_ENV,
    SERVER_TYPE,
  }
  window.GB_BUSINESS_TOOLS = {
    abtservice,
    daEventCenter,
    apiReport,
    schttp,
    isLogin,
    robotLinkCommon,
    UserInfoManager
  }
}

setBusinessConfig()

export {
  setBusinessConfig,
}
