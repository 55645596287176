import dayjs from 'dayjs'
import schttp from 'public/src/services/schttp'
import { EurCheckSite, UsCheckSite, ArCheckSite,  SpecialArCheckSite,
  UsJumpSite,
  ArJumpSite,
  EurJumpSite,
  RowCheckSite,
  RowJumpSite,
  RoeCheckSite,
  RoeJumpSite,
  RuCheckSite,
  RuJumpSite,
  CndCheckSite,
  CndJumpSite
} from './config'
const { IS_RW, ROW_SITE_INDEPENDENT_SWITCH, ROE_SITE_INDEPENDENT_SWITCH, RU_SITE_INDEPENDENT_SWITCH, CND_SITE_INDEPENDENT_SWITCH } = gbCommonInfo

class JumpCountry {
  locationList = []
  // 获取所有国家
  async getLocationList() {
    let result = this.getLocalData()
    if (result) return result
    let data = await schttp({
      url: '/api/common/country/query'
    })
    if (data?.length > 0) {
      if (navigator.language != 'ar' && !IS_RW) {
        data = data.map(item => {
          if (item.lang == 'ar') {
            item = {
              ...item, 
              host: 'm.shein.com/ar-en',
              lang: 'ar-en',
              siteUid: 'pwaren'
            }
          } 
          return item
        })
      }
      localStorage.setItem('LOCATION_LIST', JSON.stringify({
        value: data,
        expires: dayjs().add(0.5, 'hour').valueOf()
      }))
    }
    let list = data?.length > 0 ? data : this.locationList
    return list
  }
  // 获取本地数据
  getLocalData () {
    let location_data = ''
    try {
      location_data = JSON.parse(localStorage.getItem('LOCATION_LIST'))
    } catch(e) {
      console.log(e, 'locationDataParseError')
    }
    // 本地无数据
    if (!location_data) return null
    
    const list = location_data.value || []
    this.locationList = list

    // 本地数据已过期
    if (dayjs().valueOf() > location_data.expires) return null
    
    // 添加国家手机号区码字段，兼容线上数据，后面可以删掉
    const firstData = location_data?.value?.[1] || {}
    if (!firstData.phone_code) return null

    return location_data.value || []
  }
  // 校验国家是否需要跳转
  checkCountryJump (id) {
    let checkSite = EurCheckSite.concat(UsCheckSite).concat(ArCheckSite)
    if (ROW_SITE_INDEPENDENT_SWITCH == 1) {
      checkSite = checkSite.concat(SpecialArCheckSite)
      checkSite = IS_RW ? checkSite : checkSite.concat(RowCheckSite)
    }
    if (ROE_SITE_INDEPENDENT_SWITCH == 1) {
      checkSite = checkSite.concat(RoeCheckSite)
    }
    if (RU_SITE_INDEPENDENT_SWITCH == 1) {
      checkSite = checkSite.concat(RuCheckSite)
    }
    if (CND_SITE_INDEPENDENT_SWITCH == 1) {
      checkSite = checkSite.concat(CndCheckSite)
    }
    return checkSite.includes(+id)
  }
  // 校验站点是否需要跳转
  checkJumpSite (site) {
    let checkJumpSite = EurJumpSite.concat(UsJumpSite).concat(ArJumpSite)
    if (ROW_SITE_INDEPENDENT_SWITCH == 1) {
      checkJumpSite = IS_RW ? checkJumpSite : checkJumpSite.concat(RowJumpSite)
    }
    if (ROE_SITE_INDEPENDENT_SWITCH == 1) {
      checkJumpSite = checkJumpSite.concat(RoeJumpSite)
    }
    if (RU_SITE_INDEPENDENT_SWITCH == 1) {
      checkJumpSite = checkJumpSite.concat(RuJumpSite)
    }
    if (CND_SITE_INDEPENDENT_SWITCH == 1) {
      checkJumpSite = checkJumpSite.concat(CndJumpSite)
    }
    return checkJumpSite.includes(site)
  }
}

export default new JumpCountry()
