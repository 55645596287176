import schttp from 'public/src/services/schttp'

/**
通过订单号获取编辑地址的条件配置
* @param {Object} params[必填] 请求参数
* @param {Object} params.orderInfo[必填] billnoList: 订单集合
* @param {string} sceneType.sceneType[选填] sceneType: 场景类型
*/
export const getEditAddressConditionConfig = async ({ billnoList = [], sceneType = '' } = {}) => {
  if (!billnoList.length) return []

  // 根据订单号请求是否可以编辑地址信息
  let res = await schttp({
    url: '/api/orders/base/queryCouldModifyAddress/query',
    method: 'POST',
    data: {
      billno_list: billnoList,
      source: sceneType
    }
  })?.catch(() => {})

  return res?.info?.order_list || []
}

/**
获取批量编辑地址信息
* @param {Object} params[必填] 请求参数
* @param {Object} params.billno[必填] billno: 订单号
*/
export const getBatchEditAddressInfo = async ({ billno = '', sceneType = '', orderStatus = '' } = {}) => {
  if (!billno) return {}

  // 根据订单号请求是否可以编辑地址信息
  let res = await schttp({
    url: `/api/orders/query/batchModifyAddress/get`,
    method: 'POST',
    data: {
      billno,
      sceneType,
      orderStatus
    }
  })?.catch(() => {})

  return res?.info || {}
}

/**
获取批量编辑地址信息
* @param {Object} params[必填] 请求参数
* @param {Object} params.editAddressNewConfig[必填] 编辑地址配置
* @param {Object} params.language[必填] 多语言配置
*/
export const getSuccessHandleTipText = (editAddressNewConfig = {}, language = {}) => {
  let { editable_type, combined_shipped_flag } = editAddressNewConfig
  let allShippedTextList = ['all_shipped']

  if (combined_shipped_flag && allShippedTextList.includes(editable_type)) {
    let languageMap = {
      1: 'SHEIN_KEY_PWA_29033',
      default: 'SHEIN_KEY_PWA_30541'
    }
    let languageKey = languageMap[combined_shipped_flag] || languageMap['default']
    return language[languageKey] || ''
  }

  let editableTypeMap = {
    'partly_shipped_after_editable': 'SHEIN_KEY_PWA_21428',
    'partly_shipped_before_editable': 'SHEIN_KEY_PWA_21427',
    'partly_shipped_all_editable': 'SHEIN_KEY_PWA_21426',
    'unshipped': '',
    'unpaid': ''
  }

  let languageKey = editableTypeMap[editable_type] || ''
  return language[languageKey] || ''
}

/**
获取批量编辑地址信息
* @param {Object} params[必填] 请求参数
* @param {Object} params.editAddressNewConfig[必填] 编辑地址配置
* @param {Object} params.language[必填] 多语言配置
*/
export const getErrorHandleTipText = (editAddressNewConfig = {}, language = {}) => {
  let { editable_type, combined_shipped_flag } = editAddressNewConfig
  let allShippedTextList = ['all_shipped']

  if (combined_shipped_flag && allShippedTextList.includes(editable_type)) {
    return language['SHEIN_KEY_PWA_21424'] || ''
  }

  return ''
}

/**
是否是已支付关联订单号取值
* @param {Object} params[必填] 请求参数
* @param {Object} params.editAddressNewConfig[必填] 编辑地址配置
* @param {Object} params.order[必填] 订单信息
*/
// 
export const isRelationBillno = (editAddressNewConfig = {}) => {
  let { billno = '', sub_billno = '', editable_type = '' } = editAddressNewConfig || {}
  let editableTypeList = ['unpaid', 'all_shipped', 'partly_shipped_after_editable']

  return !editableTypeList.includes(editable_type) ? sub_billno : billno
}

/**
 * 推荐弹框点击交互事件数据采集
 * @param {Object} orderInfo[必填] 订单信息
 * @param {Object} checkOrderAddressInfo[必填] 推荐地址信息
 * @param {Object} order_modify_result_type[必填] 订单修改结果 0: 默认值 1：修改成功 2：修改失败 3：关闭弹窗
 * @param {Object} pop_recommend_result_type[必填] 推荐弹窗操作结果 1: 关闭推荐弹窗 2:使用原订单地址（不做修改使用Use This）3:使用推荐弹窗推荐地址（use this）4：跳地址表单修改
*/
export const sendSyncAddressRecommendResult = ({ orderInfo, checkOrderAddressInfo, order_modify_result, pop_recommend_result }) => {
  let { billno } = orderInfo || {}
  let { check_type: pop_recommend_rule, display_type: pop_recommend_type, requestId: request_id } = checkOrderAddressInfo || {}

  let params =  {
    request_id,
    pop_recommend_type,
    pop_recommend_rule,
    distribution_type: 1,
    pop_recommend_address: 1,
    order_modify_result,
    pop_recommend_result,
    order_no: billno
  }

  schttp({
    url: `/api/orders/base/querySyncAddressRecommendResult/query`,
    method: 'POST',
    data: params
  })?.catch(() => {})
}
