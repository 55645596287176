const analysis = {
  reviewUploadImg: function (val) {
  },
  reviewSubmit: function (val) {
    if (val) {
      sa('send', { activity_name: 'click_submit', activity_param: {} })
    }
  },
  clickAdressEdit () {
    sa('send', { activity_name: 'expose_edit_address_form' })
  },
  editAddress (code, msg, tag = 0, isCod = false) {
    const result = code == 0 ? 1 : 2
    let reason = code == 0 ? '' : code
    code == 2 && (reason = msg)
    sa('send', {
      activity_name: 'click_edit_a_shipping_address',
      activity_param: {
        result: result,
        result_reason: reason,
        associationAddress: +tag === 2 ? 'Y' : 'N',
        order_type: isCod ? 0 : 1
      }
    })
  },
  popUpsCheckAddress () {
  },
  clickOkCheckAddress () {
  },
  clickEditBillingAddress (billno) {
    sa('send', { activity_name: 'expose_billing_address_edit_popup', activity_param: { order_id: billno } })
  },
  clickSaveBillingAddress (billno) {
    sa('send', { activity_name: 'click_billing_address_save', activity_param: { order_id: billno } })
  },
  saveBillingAddress (value, msg, billno) {
    sa('send', {
      activity_name: 'click_billing_address_save_result',
      activity_param: {
        order_id: billno,
        result: value == 0 ? 1 : 2,
        result_reason: msg
      }
    })
  },
  clickGoods (label) {
  },
  clickAmount () {
    sa('send', {
      activity_name: 'click_amout_question_mark', 
      activity_param: {} 
    })
  },
  exposeAmountQuestion () {
    sa('send', {
      activity_name: 'expose_amout_question_mark', 
      activity_param: {} 
    })
  },  
  ClickExchange () {
  },   
  ClickItems (sku, catId, price, index, type) {
  },
  ClickExchange_confirm (data, type) {
  },  
  ClickSearch (type) {
  },
  click_exchange_confirm (data = {}) {
    sa('send', {
      activity_name: 'click_exchange_confirm',
      activity_param: data
    }, { beacon: 1 })
  },
  expose_popup_exchange_popup () {
    sa('send', {
      activity_name: 'expose_popup_exchange_popup',
      activity_param: ''
    })
  },
  click_exchange_product_image (data = {}) {
    sa('send', {
      activity_name: 'click_exchange_product_image',
      activity_param: data
    })
  },
  click_exchange_search () {
    sa('send', {
      activity_name: 'click_exchange_search',
      activity_param: ''
    })
  },
  click_exchange_search_comfirm () {
    sa('send', {
      activity_name: 'click_exchange_search_comfirm',
      activity_param: ''
    })
  },
  expose_exchange_confirm (data = {}) {
    sa('send', {
      activity_name: 'expose_exchange_confirm',
      activity_param: data
    })
  },
  expose_popup_exchange_notice (data = {}) {
    sa('send', {
      activity_name: 'expose_popup_exchange_notice',
      activity_param: data
    })
  },
  click_cancel_inline (data = {}) {
    sa('send', {
      activity_name: 'click_cancel_inline',
      activity_param: data
    }, { beacon: 1 })
  },
  click_exchange (data = {}) {
    sa('send', {
      activity_name: 'click_exchange',
      activity_param: data
    }, { beacon: 1 })
  },
  expose_popup_exchange_searchlist () {
    sa('send', {
      activity_name: 'expose_popup_exchange_searchlist',
      activity_param: ''
    })
  }
}

export { analysis }
