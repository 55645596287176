import addressVueV2 from './index_v2.vue'

function createComponent() {
  return import('./index_v2.vue')
}

function creator() {
  return () => ({
    error: addressVueV2,
    component: createComponent(),
    timeout: 3000
  })
}

export default creator()
